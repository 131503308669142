import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Software/Windows/NavButtons';
import ForumBox from 'components/Software/Windows/VLC_Player/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Windows Software for your INSTAR IP Camera",
  "path": "/Software/Windows/VLC_Player/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "The Windows app VLC Player for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features.",
  "image": "./P_SearchThumb_VLC.png",
  "social": "/images/Search/P_SearchThumb_VLC.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-VLC_white.webp",
  "chapter": "Software"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='VLC Player' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='The Windows app VLC Player for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features.' image='/images/Search/P_SearchThumb_VLC.png' twitter='/images/Search/P_SearchThumb_VLC.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/VLC_Player/' locationFR='/fr/Software/Windows/VLC_Player/' crumbLabel="VLC Player" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#windows",
        "aria-label": "windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows`}</h2>
    <h2 {...{
      "id": "vlc-player",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#vlc-player",
        "aria-label": "vlc player permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`VLC Player`}</h2>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#vlc-player"
        }}>{`VLC Player`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#vorbereitung"
            }}>{`Vorbereitung`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#%C3%B6ffnen-des-network-stream-assistant"
            }}>{`Öffnen des Network Stream Assistant`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#hinzuf%C3%BCgen-einer-hd-kamera"
                }}>{`Hinzufügen einer HD Kamera`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#hinzuf%C3%BCgen-einer-vga-kamera"
                }}>{`Hinzufügen einer VGA Kamera`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#erweiterte-optionen"
                }}>{`Erweiterte Optionen`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#fehlerbehebung-bei-abgehacktem-video"
            }}>{`Fehlerbehebung bei abgehacktem Video`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#erh%C3%B6hen-sie-die-cache-werte"
                }}>{`Erhöhen Sie die Cache-Werte`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <p>{`VLC is a free and open source cross-platform multimedia player and framework that plays most multimedia files as well as DVDs, Audio CDs, VCDs, and various streaming protocols. You can download the free player `}<a href="http://www.videolan.org/vlc/" target="_blank" rel="noopener noreferrer">{`here`}</a>{`. After installing the software start the user interface.`}</p>
    <h3 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Preparation`}</h3>
    <p>{`Please make sure that the RTSP Streamen over TCP is activated. This setting can be found in the `}<strong parentName="p">{`Tools`}</strong>{` menu under `}<strong parentName="p">{`Preferences`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "791px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1d4866d616e43b795a9576642b6676a5/cc8d6/VLC_TCP_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABkUlEQVQY02OIO7IuYP/aoIPrgk9uDT61LfjkVvfDm50PbQ46sSXoxNbQMztCTm8POb09/NyuiPO7wWhXxNmdUWd3+Z7cwmBvbmFnbmFvbuFgbulsZWOqrdPZ2v3m5fv71268uPfg96cv396+///9Z0lOrr2ZuZejk4e9g4eDo5e1nUNwAAMyYGZmZmBgyEkJ//j6ybnzl65du/7s+fPHT558+PjRwsKCgYGBCayAgZERREqLY9FckJn888vH2zduPbz/4MO7d48fPnz7+o21lRVcAUQzs6wkAx8fHy8MCAgIMDAwFBUVvXj56szpsw8fPX777t3Dh4/evHnr5OTEwMDAw8PDxcXFzcMryMEroKnGcPHihTNnzpw8dfLMmdNnTp86fOL05Ru3Xz55+Oj+3eePH7x79eLzh7efP767dfv2zRs3weDGlSsXj10+tPXqaYasgpKsgpLM/JKswtKcwtKE7MKpnZNPbjy0Y8nW/Wv3n9x+8uS24+e2n+hu7sivqCmtriuprMsqKgzOjYktzAMAhMDLk1p+M7QAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1d4866d616e43b795a9576642b6676a5/e4706/VLC_TCP_01.avif 230w", "/en/static/1d4866d616e43b795a9576642b6676a5/d1af7/VLC_TCP_01.avif 460w", "/en/static/1d4866d616e43b795a9576642b6676a5/8f986/VLC_TCP_01.avif 791w"],
              "sizes": "(max-width: 791px) 100vw, 791px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1d4866d616e43b795a9576642b6676a5/a0b58/VLC_TCP_01.webp 230w", "/en/static/1d4866d616e43b795a9576642b6676a5/bc10c/VLC_TCP_01.webp 460w", "/en/static/1d4866d616e43b795a9576642b6676a5/5f564/VLC_TCP_01.webp 791w"],
              "sizes": "(max-width: 791px) 100vw, 791px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1d4866d616e43b795a9576642b6676a5/81c8e/VLC_TCP_01.png 230w", "/en/static/1d4866d616e43b795a9576642b6676a5/08a84/VLC_TCP_01.png 460w", "/en/static/1d4866d616e43b795a9576642b6676a5/cc8d6/VLC_TCP_01.png 791w"],
              "sizes": "(max-width: 791px) 100vw, 791px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1d4866d616e43b795a9576642b6676a5/cc8d6/VLC_TCP_01.png",
              "alt": "RTSP Stream over TCP",
              "title": "RTSP Stream over TCP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on the `}<strong parentName="p">{`Input / Codecs`}</strong>{` tab and scroll down to the `}<strong parentName="p">{`Network`}</strong>{` section and select `}<strong parentName="p">{`RTP over RTSP (TCP)`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "788px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2eb87eb70edce2146824f66ccb102b04/ea7fb/VLC_TCP_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "86.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAA7CAAAOwgEVKEqAAAACsElEQVQ4y4VT227TUBD0hyKQUIp45tcQvSRRH1Arml4ISeM4cez4fr+7SUCi1aDZ1CWoDzyMjs/Zc2ZndtfKp6vPOLo8Ru/iC95/O0Hv5gxHtwMc3Q3x4W6Aj9+HeDsa4M31Od6NBnLGeO+mj95tf79en+0xOoWyKzOYqwvg12941308bTYoZyOUSxWt78Ed38CJdzg9GWLh71CoV9ilKZ42P/HYbvD4cIgtFNezcXn3FWEUQZ+O4HgerOUEkW/BME1MJxPM7QD9iyvc2wmMxQRhFKBqGhRVhbL+C+4Vy7Kx0JaYqSqmsznG4zFs10fdtEiSBHEcI8sylFWFLE2Q5SXSNJXYIXgvz3MoRVFitVrBtm2EYYg4TtDUNZqmRuD7WK/Xop4P9o/jV2QdiqKAkqUZ5vM5NE2Drq+g67pkqutalFRVBc/zkCSpnBN82H0TdMAzilJ4YK0tUUJCwzAl2DSNkDMZCXnZcRxYliWO+N2hi5FDKfJcLqyfST3PF3Uk5N4wDCF0XVdWEnI9VNkpFMI8z6AvdTiOizRJJUCbBC+zGWVZ/gPGmLRDt2cyhXWybQe+70smNqN71K3/Q0csCrMsh+u40kXa3ZM2L2Rt28r+UFEHnvOOsTLg+8G+KSTQtIV0mVitDCkwCeMolnoxWTd7XA+/uXLcuIplKjRNUyyz8AyyjlQQBMF+DsPo1RALogjJ83ySXCxzs1gspY5sAi3SBu0wQRCEUoaIwx1FiEhQlkiKAildMCY/RPw8NkUpUk3DFHWHhWYyWo6iGBU7zEbFMYL+EOHwHMHxKbIfE5QUUZad5UyGmXWjisPO0jpt83eU8SDyHNFMRaRpCKf3KCwLdcumVWJbIRH/CKpjBlVV4XreS2e7Lr+MCbu73aLdbpE/7DA2fEzNEJqbYekX+APgDNpTHflLAAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2eb87eb70edce2146824f66ccb102b04/e4706/VLC_TCP_02.avif 230w", "/en/static/2eb87eb70edce2146824f66ccb102b04/d1af7/VLC_TCP_02.avif 460w", "/en/static/2eb87eb70edce2146824f66ccb102b04/4fa30/VLC_TCP_02.avif 788w"],
              "sizes": "(max-width: 788px) 100vw, 788px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2eb87eb70edce2146824f66ccb102b04/a0b58/VLC_TCP_02.webp 230w", "/en/static/2eb87eb70edce2146824f66ccb102b04/bc10c/VLC_TCP_02.webp 460w", "/en/static/2eb87eb70edce2146824f66ccb102b04/4d911/VLC_TCP_02.webp 788w"],
              "sizes": "(max-width: 788px) 100vw, 788px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2eb87eb70edce2146824f66ccb102b04/81c8e/VLC_TCP_02.png 230w", "/en/static/2eb87eb70edce2146824f66ccb102b04/08a84/VLC_TCP_02.png 460w", "/en/static/2eb87eb70edce2146824f66ccb102b04/ea7fb/VLC_TCP_02.png 788w"],
              "sizes": "(max-width: 788px) 100vw, 788px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2eb87eb70edce2146824f66ccb102b04/ea7fb/VLC_TCP_02.png",
              "alt": "RTSP Stream over TCP",
              "title": "RTSP Stream over TCP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "open-network-stream-assistant",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#open-network-stream-assistant",
        "aria-label": "open network stream assistant permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Open Network Stream Assistant`}</h3>
    <p>{`Go to Media and choose to open a network stream - or simply type `}<strong parentName="p">{`CTRL+N`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "610px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0f13ca87f3d475d4b2ac31bc2abeb47f/5aae9/VLC_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC0ElEQVQ4y4WS224TVxSGZ5zTY8BFryuVIIWDVOAF+gatEBchB8RLILgvN6hIVaUm0KqOx3ES4hAnEiAlcZKKUzy24yMzY8/4MB58thOMPjTbAQURhS19WmvWaP3730tLmg085w9/mAe+DR4GtpkJRZl1WYsyE1JFPrOq8t+LFPNhHf+WRmDbIBA2+nEzy/xmhqWVMAtzq0ih9Q02tt6y8zJOLGESTxUE++kiezEDdT9PRncIroV5+JeXP/9W+Ne3yj9zTwWPvUEee1d4NKug+NeQkhkLPaORiicp5Cycos1Bo8Nhs0ulUCYeidFrHzI9OYUkSQx4PCIexyPLIo5evIaUSevkNYP0foqCYdKqNujW2xw0O1SKNloqCz2YmpjsN58keFQbu3wVyX2els2RSrzDses0ax0atQ7t5iFFq0I6peOeyYkjhwMDJwj2a6MXriK9iqpkMgbRaBpVTYnoVFq0Wx8x8xWSCU0I3pyY/qr5JIfnxq4gFYtlCkZezNHIauS1HKaWo1p2BJaeF4K3J8ZPcXhM0HVjF8qYhikomSWa7xu0ai1a1SY1uyoE79+7890ZHgk2sK0S2WQWPaPzvuzQbXQ4aHaxCzamZtA9hOLCXW6NugIyHvkUQdO0yWV1clqOeqUmhDr1tsB1q6Wz9D7A4u/j/PKjK+BBPk2wVu0IV2WrRK1SxbbKNJw6fIBetycucM+N8duiaWjwhBkezfXchStI4Rdb7G5uE3kTEUscfasSi8TEou+93mN3a0cs/PXffhVNsvztDD/Xfhr7GenM2R/Ex8jICMPDw18YGhoSDA4OCj4/S5blL0gC12H/3/lL15CWl9d4srCE4lVQ5hQCSgCf10dAmcfv84t8cX6R5cXlbwgu9QkthVgPrrL+bBspoiYo5C2iaox4NI5TdkRu5SwS+0l2d/4XKyROD/h4jB5UK3WC71bwWo8olVp8AuMVDfW7hgP7AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0f13ca87f3d475d4b2ac31bc2abeb47f/e4706/VLC_01.avif 230w", "/en/static/0f13ca87f3d475d4b2ac31bc2abeb47f/d1af7/VLC_01.avif 460w", "/en/static/0f13ca87f3d475d4b2ac31bc2abeb47f/41fd6/VLC_01.avif 610w"],
              "sizes": "(max-width: 610px) 100vw, 610px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0f13ca87f3d475d4b2ac31bc2abeb47f/a0b58/VLC_01.webp 230w", "/en/static/0f13ca87f3d475d4b2ac31bc2abeb47f/bc10c/VLC_01.webp 460w", "/en/static/0f13ca87f3d475d4b2ac31bc2abeb47f/04445/VLC_01.webp 610w"],
              "sizes": "(max-width: 610px) 100vw, 610px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0f13ca87f3d475d4b2ac31bc2abeb47f/81c8e/VLC_01.png 230w", "/en/static/0f13ca87f3d475d4b2ac31bc2abeb47f/08a84/VLC_01.png 460w", "/en/static/0f13ca87f3d475d4b2ac31bc2abeb47f/5aae9/VLC_01.png 610w"],
              "sizes": "(max-width: 610px) 100vw, 610px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0f13ca87f3d475d4b2ac31bc2abeb47f/5aae9/VLC_01.png",
              "alt": "VLC Player for your INSTAR IP Camera",
              "title": "VLC Player for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open the program and add a network stream.`}</p>
    <h4 {...{
      "id": "add-a-new-hd-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#add-a-new-hd-camera",
        "aria-label": "add a new hd camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a new HD Camera`}</h4>
    <p>{`To add an INSTAR HD camera type in the RTSP streaming address (see Installation Guide). You can either use the local IP address (e.g. `}<strong parentName="p">{`192.168.1.101:554`}</strong>{`) to connect to a camera inside your local area network or use the `}<a parentName="p" {...{
        "href": "/en/Internet_Access/The_DDNS_Service/"
      }}>{`Camera´s DDNS Address`}</a>{` (e.g. `}<strong parentName="p">{`1234ab.ddns-instar.com:554`}</strong>{` to connect to your camera from a different network.`}</p>
    <p>{`Your INSTAR HD camera offers you three RTSP streams with different resolutions. To minimize your network load it is recommended to use a stream with a lower resolution - especially if you access your camera over the internet. For a camera with the network address 1234ab.ddns-instar.com that is `}<a parentName="p" {...{
        "href": "/en/Internet_Access/Port_Forwarding/"
      }}>{`Forwarded`}</a>{` on the RTSP port 554 (see Web User Interface Network/IP Configuration) and the default login `}<strong parentName="p">{`user = admin`}</strong>{` and `}<strong parentName="p">{`password = instar`}</strong>{` you have the following three options:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`High Bandwidth`}</strong>{`: rtsp://admin:`}<a parentName="li" {...{
          "href": "mailto:instar@1234ab.ddns-instar.com"
        }}>{`instar@1234ab.ddns-instar.com`}</a>{`:554/11`}</li>
      <li parentName="ul"><strong parentName="li">{`Normal Bandwidth`}</strong>{`: rtsp://admin:`}<a parentName="li" {...{
          "href": "mailto:instar@1234ab.ddns-instar.com"
        }}>{`instar@1234ab.ddns-instar.com`}</a>{`:554/12`}</li>
      <li parentName="ul"><strong parentName="li">{`Low Bandwidth`}</strong>{`: rtsp://admin:`}<a parentName="li" {...{
          "href": "mailto:instar@1234ab.ddns-instar.com"
        }}>{`instar@1234ab.ddns-instar.com`}</a>{`:554/13`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "543px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c4fe266b9a3dd8c377eeb940efa18040/29579/VLC_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACOklEQVQ4y6WRWW/TQBRG/f9/A49UoqKUgAQPSAiUVmVRESqhLTSOl3i8L4l3O459kJ0oXURbCa51POOxfOa7Y+nZq/c8ffOVJ6PP7L2bMPqkc3A04/BY4cWxwuGxyuhEZ3SiDfRrz8fyjoOxzP7HX7zcf8to7zXS5ZVAVeZoqkDVLFRVYNkBqm4SBDHaXOXnxYSZPGd6peLaIXlSkcYl2ZZkWVBECcJcIv1WHIRhoCgKmj5HCEGzbkjThCzLWMYBhtAQwhzeLRdL6qqiKkuqstrR1DWmlyLNrZBFFGIYAsdxCMOQKIqo65r7qu06uhtsnlu8MENSRUASL5nNZui6znQ6RZZnWJZFEAQYhkGSpjTNmtVqRdM0d1gPm9dVieNnSLLuQW/3/CFd32ae56RpSlVVtG070MGtVLfpc3cIJ0Gazf1BaJoWtmXjeR6u6+LYDotoQde2PFbd9i7c9FqYJOmQrh+TJBmI45iyLDfnNCTt/sJmvXdshIb/eIJ7W73+KX1CsxcqW2H7wAePb7gZB6G6FT6W4mFuCsU2Ydv9l3TXsiYC/qW64bo+451wqtr4rodjuVhmPw/I04IsyTekOfkdijSnKirKvKLISsq8pilrhJMiGcKnCCNiN2DpeOThAsqarqigJ6/o8oom66lZ5yvKuMTWLQLTIvF83LlBKAxcN0E6ndpobopsx8ychEstZCL7nKsBk34+j7g0IgzbR9gewnHQhcrpjzPGZwpfLgQfvsmMv8scnbv8Af1jxcCPZ02yAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c4fe266b9a3dd8c377eeb940efa18040/e4706/VLC_02.avif 230w", "/en/static/c4fe266b9a3dd8c377eeb940efa18040/d1af7/VLC_02.avif 460w", "/en/static/c4fe266b9a3dd8c377eeb940efa18040/36249/VLC_02.avif 543w"],
              "sizes": "(max-width: 543px) 100vw, 543px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c4fe266b9a3dd8c377eeb940efa18040/a0b58/VLC_02.webp 230w", "/en/static/c4fe266b9a3dd8c377eeb940efa18040/bc10c/VLC_02.webp 460w", "/en/static/c4fe266b9a3dd8c377eeb940efa18040/4b567/VLC_02.webp 543w"],
              "sizes": "(max-width: 543px) 100vw, 543px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c4fe266b9a3dd8c377eeb940efa18040/81c8e/VLC_02.png 230w", "/en/static/c4fe266b9a3dd8c377eeb940efa18040/08a84/VLC_02.png 460w", "/en/static/c4fe266b9a3dd8c377eeb940efa18040/29579/VLC_02.png 543w"],
              "sizes": "(max-width: 543px) 100vw, 543px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c4fe266b9a3dd8c377eeb940efa18040/29579/VLC_02.png",
              "alt": "VLC Player for your INSTAR IP Camera",
              "title": "VLC Player for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The HD camera´s video stream will be loaded after clicking on Play:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "657px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1539605c13113236afedcd774a648029/a1253/VLC_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAADYUlEQVQozwFWA6n8ALC5xai4zKO0yqi4zaa3zKq70aq5z6OyyK/A2LTG3rPE3LPE3LfJ4bfJ4bfJ4bfJ4bfH37XG3Lq6yrGruQDAz97G1+XC1OHF2+rC2efE3OnE3OrA1uPF3uvH3+zH3+vG3urP5vPO5fLO5fLM5PPL4/HK4O/L4O+81OUAqsfaqsverM3fqszers/frtDgstLhtdXis9ThstPgs9Xgs9ThsNDer87crc3cqsvbqcnZpsbXocTXocHUAJ+0xJuywKW5xKK1wqK2wqS4xKe6xKm8xau9xam9xaa8xaS5xKC2wZ+1wJ6zwJ2yvpmvvZqvvJ6yvZmxwQCosrWhqKWlrKihqKOpr6ivs6mtr6WtsKapraOrrqOrrqWfpqGjqqWjrKadp6KQnJ97j51yiZxngppri6YAo6mloaSXoKSXoqGRo6OSoaCPp6SRqaaRsK2Wq6eRp6SQpqKPnpyMpaSVnp6RcYyaS3ORRGyNO2KITnSYAHOEj3SCg6WtpKqsnJydkKqljq2pjrStkqihh7atj6mhh5WHdpuYhpOPgJiXiIyUi3GAhl52hE1rf1d4kABFY30nRFs1Tl5wf4OdpJ6jpZOZj3iTkoWnqZm2sZCvqIqinIOblYCPj4CQk4WLjYCTk4aHjINxd3FygYQARGB3GDhQDStGXGtulZ2Sg4R6mYl0lJB/gH91foN8k5iMm5eBl5B2lJGAlpmLoKKNnJ2LfYSAfH90ipSQAD1WbCA2SCQ6TC5ATV1lZm56fHF6eVtkZktWWkVWYkldaWN1fmJzd2FhWn2Ad4qNfn+BdnR6dW9zbXmEgwBreYJtbWJrdnhNXGVJXWtMY3M7TlxLYm5PZ3c7Rk9FWGQ7TVc+U2FGXWtHWmZJW2ZbbXZUY2tjaWdwgYsAYHKAOExUOE9aQFVmRl9vQ1hnUGp8UWh4TWBxOUdTR2F0MEJPOU1cQVlpL0RSN01dYYWgR2R5OUlWT2NwALW5w8C/w728v7+9wMPBxL69wMXExru4ucHAw9HT18vMz8zN0c3N0MbDxczKzc3LztHP1NLT2MHDwrG5wgC3v8nIzdPAxszEytDEytG4vsXP1Nu5wcfDydDh4+zg4+vh5Ozh5Ozi5u/h5e3h5e3Z3eayzr6h1afG1N2ZlQHSqE1LwgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1539605c13113236afedcd774a648029/e4706/VLC_04.avif 230w", "/en/static/1539605c13113236afedcd774a648029/d1af7/VLC_04.avif 460w", "/en/static/1539605c13113236afedcd774a648029/b5dc5/VLC_04.avif 657w"],
              "sizes": "(max-width: 657px) 100vw, 657px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1539605c13113236afedcd774a648029/a0b58/VLC_04.webp 230w", "/en/static/1539605c13113236afedcd774a648029/bc10c/VLC_04.webp 460w", "/en/static/1539605c13113236afedcd774a648029/b9f96/VLC_04.webp 657w"],
              "sizes": "(max-width: 657px) 100vw, 657px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1539605c13113236afedcd774a648029/81c8e/VLC_04.png 230w", "/en/static/1539605c13113236afedcd774a648029/08a84/VLC_04.png 460w", "/en/static/1539605c13113236afedcd774a648029/a1253/VLC_04.png 657w"],
              "sizes": "(max-width: 657px) 100vw, 657px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1539605c13113236afedcd774a648029/a1253/VLC_04.png",
              "alt": "VLC Player for your INSTAR IP Camera",
              "title": "VLC Player for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "add-a-new-vga-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#add-a-new-vga-camera",
        "aria-label": "add a new vga camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a new VGA Camera`}</h4>
    <p>{`To add an INSTAR VGA camera type in the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/CGI_Commands/VGA_Series_CGI_List/"
      }}>{`CGI Command`}</a>{` for the network MJPEG stream. You can either use the local IP address (e.g. `}<strong parentName="p">{`192.168.1.101:80`}</strong>{`) to connect to a camera inside your local area network or use the camera´s `}<a parentName="p" {...{
        "href": "/en/Internet_Access/The_DDNS_Service/"
      }}>{`DDNS Address`}</a>{` (e.g. `}<strong parentName="p">{`1234ab.ddns-instar.com:80`}</strong>{` to connect to your camera from a different network.`}</p>
    <p>{`Your INSTAR VGA camera offers you two streams - the MJPEG stream and the ASF stream that adds a sound track to the stream, if your camera has a microphone. For a camera with the network address 1234ab.ddns-instar.com that is `}<a parentName="p" {...{
        "href": "/en/Internet_Access/Port_Forwarding/"
      }}>{`Forwarded`}</a>{` on the HTTP port 80 (see Web User Interface Network/IP Configuration) and uses the default login user = admin and password = none you have the following two options:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`MJPEG Stream`}</strong>{`: `}<a parentName="li" {...{
          "href": "http://1234ab.ddns-instar.com:80/videostream.cgi?user=admin&pwd="
        }}>{`http://1234ab.ddns-instar.com:80/videostream.cgi?user=admin&pwd=`}</a></li>
      <li parentName="ul"><strong parentName="li">{`MJPEG + Audio Stream`}</strong>{`: `}<a parentName="li" {...{
          "href": "http://1234ab.ddns-instar.com:80/videostream.asf?user=admin&pwd=/span%3E"
        }}>{`http://1234ab.ddns-instar.com:80/videostream.asf?user=admin&pwd=/span>`}</a></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "543px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0e917f4ff24406810104c1dd9d26982d/29579/VLC_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80.8695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACI0lEQVQ4y52SW2/aQBSE/f9/RZ/6EClKiujtpVLbXEoSqU1CUwr4EhvWNmBjG/AF7P0qLyShURqqnNV4Vkc+szNra40Pn3n9/pxXjRZ7n37SbNkcHhs0Ti3enJg0Tm5pthya32q+Vb2DI52DY13x4bHO/pcuzb237O+9Q+v1Hfr9WyzLRTcEpjnEHozRrSH+KEK3DNo3N/R6Dt2uhesGJFFGPE3vMQ1TssmUKytAs5wRlmliGgaGYTJwHMqyJEliFosFwdTn1tZV37Ft4mnEMs/Js4xigyzNYFnQdmdotYswCHAcB9/3CYKAMJyyKkv+VZWUSCkV1yilrLv88hI015sShgGGYeA4A2zbVvuaPc+7hxBCHRrHsUqwWq02KMnygqrIufFmaM5wQlWVjMdjwjBkPp+rqHEUq31RFOR5seFcCdQlNy7vnNYOr0WyFpRVqU4XQ6Hc1NE91yOKIjWwq+Tm+SAoK5IkUY5qvkMdL8vytYuq2oLcQkVZPXK408FWvKewjixpbws+N7Crqs0r/y242+ETgvV9vFxwK/JABLykpFoPP/r9V9ZNl4k/whM+YugyGU1I5ymL2eIZpORpTrbISeeZYpnlXA8TNCECFmFEPA6J/AmLMEZmK6p0icz+xl1vOUvxHcHE9dWc6wyJhEvHm6OdXfbRLY+uIegZLl1d0OkN6PQHa36M/oB2x+KodcnpRZuLy998bf3g7PyKj98N/gBHIcM6qHBugQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e917f4ff24406810104c1dd9d26982d/e4706/VLC_03.avif 230w", "/en/static/0e917f4ff24406810104c1dd9d26982d/d1af7/VLC_03.avif 460w", "/en/static/0e917f4ff24406810104c1dd9d26982d/36249/VLC_03.avif 543w"],
              "sizes": "(max-width: 543px) 100vw, 543px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0e917f4ff24406810104c1dd9d26982d/a0b58/VLC_03.webp 230w", "/en/static/0e917f4ff24406810104c1dd9d26982d/bc10c/VLC_03.webp 460w", "/en/static/0e917f4ff24406810104c1dd9d26982d/4b567/VLC_03.webp 543w"],
              "sizes": "(max-width: 543px) 100vw, 543px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e917f4ff24406810104c1dd9d26982d/81c8e/VLC_03.png 230w", "/en/static/0e917f4ff24406810104c1dd9d26982d/08a84/VLC_03.png 460w", "/en/static/0e917f4ff24406810104c1dd9d26982d/29579/VLC_03.png 543w"],
              "sizes": "(max-width: 543px) 100vw, 543px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0e917f4ff24406810104c1dd9d26982d/29579/VLC_03.png",
              "alt": "VLC Player for your INSTAR IP Camera",
              "title": "VLC Player for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The VGA camera´s video stream will be loaded after clicking on Play:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "655px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2ec8a15bcefa82ebc4dcc66867083650/ae6b7/VLC_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAETklEQVQ4yx3LWVAbZQAA4N+SkGtzLuQgB8luks2dkGUTchFCkloagVLOcCUhwQAlBVqgFdRCp9MZYSyt06rjWB/0pQ+OLZQOaDkTC0iFaqkz0FOt1YdaH6wzPrU6dOZ7/cDs8q2pG/e+Wt6e/PbelczdK5m7UzfuX07vTGbuXU7vXFt7dHX14dSN+zPrjxe2ni3e+WvpzrPFraeZ1e3rX6+BrlR/fW1DfUNTc0tbY3OksTkaiSUi0Xgi0dEWT8bakpFY+6FDPd2p3oHBocO9/cnOVHuyOx6ND4yMARy3cigAFbFhNpXHpoiFXGkeLBPxtEieVilTykU6DSKXCSW5DL1apkFEmJiN5LFJAHj8QeDyFHNYDJMB02rUBG4hcJPTVlC+L+Av8brshSVuh8Wkwy0Gsx7TatU6vdqoRRCFzGRQ1cVioNDhhugUoTDXoMNMJj2GoQaD1mw24AV6l80cKHaIBTlSEV+tkKIyEWHECGvBwb2esf54bWsc4ESRgEdHpbkKRC6RSlClgsDNRkxR4bUG7KamkMdLGI0alRLJd1iN+312O1HoIcyJal9dSwQYLATMoXNYDDmiuPDRx+fOTrx9rC/ReDB8YF/IpuurcIecRq+DCHoIT5E1XyqG+Xw2h8WgZLleD+1mGo3M5bDkCnlnZ0d7e6yyouzE6EhTtPFA0LU/WHLh3Pu315bOnx7S69RiaR6qQlUajMeGCjylwIwXkbJe48HcHD7MYtIhJsThsVUq1GoxuQlLSzg8dHzwWCqaDIdyBAK3raCkuFBn1TMYlOpkEuA2B0QjK1CMB8NMCJLA7FCpd3xsvLp6r89u0OtUNDoVN8hHO6o4LDqKIqhSzoV5oZrg+KWLwGwlqJQ9OVwWgkgqG8vr4uVt0aqlz08dPVKpU0ny80UCIbfMo7000a3RqtQmfXHQbffifaMdb7S2AjNuz4FZaoUMwfKbBiI9Ix0nTh/9cvriW6eOaM3y1q6aqqaQ0YgOd9dO9LfkCnOYLNbI+aFoqgHD3buZCdECgWAkXP/J8Xj6w+EzvbV+v63UZ/OV+aoj1UXFdjUmDQesbitm0OsOhg98OnVRLBHo7F5Q5HQL+bBBq37vcOvJ2P4yp16jFIv5PLVSVtNYiaAKAACTyRDxeXyRyEngPYOps599QKWQnKFKQNideXkiq9EklcohDgxxeUwezObCSp36nTPDlgIjOTubzoCyqTQajQoAkMmlwdDe0oAr+e4Q0BpxAEBhUXmxr4rL5QhEQpif6y/3HzrZFetJMCGIlJ1NplBJZHIWiUSH6ByYs4dEZtCpdW8mwNTkzPzMzPerN5fnFq9fm5m9Or08N39rc/3Wxs07mxsbK6uZhcVd84vp+YXM/MLKcnolnZ77Znp2aQ1c39zZfPBk6Yftte2fv9v5ZW7jp63HT2fWfsxsPXjy/MVvz1/8/s/LV/575eXDP/+dfXT7i1+vrf/x9//CnVgOP0/5MgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2ec8a15bcefa82ebc4dcc66867083650/e4706/VLC_05.avif 230w", "/en/static/2ec8a15bcefa82ebc4dcc66867083650/d1af7/VLC_05.avif 460w", "/en/static/2ec8a15bcefa82ebc4dcc66867083650/3ab81/VLC_05.avif 655w"],
              "sizes": "(max-width: 655px) 100vw, 655px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2ec8a15bcefa82ebc4dcc66867083650/a0b58/VLC_05.webp 230w", "/en/static/2ec8a15bcefa82ebc4dcc66867083650/bc10c/VLC_05.webp 460w", "/en/static/2ec8a15bcefa82ebc4dcc66867083650/09ab1/VLC_05.webp 655w"],
              "sizes": "(max-width: 655px) 100vw, 655px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2ec8a15bcefa82ebc4dcc66867083650/81c8e/VLC_05.png 230w", "/en/static/2ec8a15bcefa82ebc4dcc66867083650/08a84/VLC_05.png 460w", "/en/static/2ec8a15bcefa82ebc4dcc66867083650/ae6b7/VLC_05.png 655w"],
              "sizes": "(max-width: 655px) 100vw, 655px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2ec8a15bcefa82ebc4dcc66867083650/ae6b7/VLC_05.png",
              "alt": "VLC Player for your INSTAR IP Camera",
              "title": "VLC Player for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "advanced-options",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#advanced-options",
        "aria-label": "advanced options permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Advanced Options`}</h4>
    <p>{`VLC Player allows you to save a camera to a play list to quickly access it´s video stream and do manual video recordings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "400px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/06fca90161511ad5d96a92fbd55b49d0/066f9/VLC_Playlist_01_en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.304347826086953%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEE/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAd4KI//EABgQAAMBAQAAAAAAAAAAAAAAAAABAhES/9oACAEBAAEFAslpTCHyf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAQEBAQAAAAAAAAAAAAAAAAAyATH/2gAIAQEABj8ClGOP/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAERMXFB/9oACAEBAAE/IbRHpzzENI//2gAMAwEAAgADAAAAEIQv/8QAFhEBAQEAAAAAAAAAAAAAAAAAABFB/9oACAEDAQE/EMV//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGxAAAgIDAQAAAAAAAAAAAAAAASEAETFBUZH/2gAIAQEAAT8QRiqwG/ZUkF01ODKA6hP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/06fca90161511ad5d96a92fbd55b49d0/e4706/VLC_Playlist_01_en.avif 230w", "/en/static/06fca90161511ad5d96a92fbd55b49d0/af71e/VLC_Playlist_01_en.avif 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/06fca90161511ad5d96a92fbd55b49d0/a0b58/VLC_Playlist_01_en.webp 230w", "/en/static/06fca90161511ad5d96a92fbd55b49d0/7f61c/VLC_Playlist_01_en.webp 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/06fca90161511ad5d96a92fbd55b49d0/e83b4/VLC_Playlist_01_en.jpg 230w", "/en/static/06fca90161511ad5d96a92fbd55b49d0/066f9/VLC_Playlist_01_en.jpg 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/06fca90161511ad5d96a92fbd55b49d0/066f9/VLC_Playlist_01_en.jpg",
              "alt": "VLC Player for your INSTAR IP Camera",
              "title": "VLC Player for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Start the program and open the play list window.`}</p>
    <p><strong parentName="p">{`Step 1`}</strong>{`: Open the play list window by clicking the `}<strong parentName="p">{`Show Playlist`}</strong>{` button.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f42d2e43220efcafcda4de660b3ffd15/b4294/VLC_Playlist_02_en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.3913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAQX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHstO5pMH//xAAaEAACAgMAAAAAAAAAAAAAAAAAAQISAyEx/9oACAEBAAEFAkh1JdUi5LJv/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFhAAAwAAAAAAAAAAAAAAAAAAECAx/9oACAEBAAY/Akg//8QAGxAAAwEAAwEAAAAAAAAAAAAAAAERITFBYaH/2gAIAQEAAT8hVI4QaTwmIuiGpaz0HPh9P//aAAwDAQACAAMAAAAQAw//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEBAAICAwAAAAAAAAAAAAABEQAhMUFhkdH/2gAIAQEAAT8Q1gtlDrvEpidhZhEBGs4tIVzaQU718wk2Qns+M//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f42d2e43220efcafcda4de660b3ffd15/e4706/VLC_Playlist_02_en.avif 230w", "/en/static/f42d2e43220efcafcda4de660b3ffd15/d1af7/VLC_Playlist_02_en.avif 460w", "/en/static/f42d2e43220efcafcda4de660b3ffd15/56a33/VLC_Playlist_02_en.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f42d2e43220efcafcda4de660b3ffd15/a0b58/VLC_Playlist_02_en.webp 230w", "/en/static/f42d2e43220efcafcda4de660b3ffd15/bc10c/VLC_Playlist_02_en.webp 460w", "/en/static/f42d2e43220efcafcda4de660b3ffd15/e88ff/VLC_Playlist_02_en.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f42d2e43220efcafcda4de660b3ffd15/e83b4/VLC_Playlist_02_en.jpg 230w", "/en/static/f42d2e43220efcafcda4de660b3ffd15/e41a8/VLC_Playlist_02_en.jpg 460w", "/en/static/f42d2e43220efcafcda4de660b3ffd15/b4294/VLC_Playlist_02_en.jpg 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f42d2e43220efcafcda4de660b3ffd15/b4294/VLC_Playlist_02_en.jpg",
              "alt": "VLC Player for your INSTAR IP Camera",
              "title": "VLC Player for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Choose `}<strong parentName="p">{`Advanced Open`}</strong>{`.`}</p>
    <p><strong parentName="p">{`Step 2`}</strong>{`: Right-click the playlist window and choose `}<strong parentName="p">{`Advanced Open`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "550px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2ea34a24c5900a0f15f2abc26756c1c3/d7854/VLC_Playlist_03_en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "97.3913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAATABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAEDAgQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAenlsSUrBFYoJ//EABwQAAMAAQUAAAAAAAAAAAAAAAABAjEDEBESIf/aAAgBAQABBQJxyLTR0kWB7X5Kx//EABURAQEAAAAAAAAAAAAAAAAAABAB/9oACAEDAQE/ASH/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAYEAEAAwEAAAAAAAAAAAAAAAABACAyIf/aAAgBAQAGPwLoTJMl/wD/xAAbEAEBAAMAAwAAAAAAAAAAAAABABEhMRBx0f/aAAgBAQABPyEOkQDVo/C4evHUGibkHE1GW//aAAwDAQACAAMAAAAQ99cB/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERMf/aAAgBAwEBPxClZpD0/8QAFREBAQAAAAAAAAAAAAAAAAAAEAH/2gAIAQIBAT8QKf/EAB4QAQACAQQDAAAAAAAAAAAAAAEAESEQMZGxQXGh/9oACAEBAAE/EKcAZLhTmVHKOBPndadUIqvY8xypWZI7ItGX1P/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2ea34a24c5900a0f15f2abc26756c1c3/e4706/VLC_Playlist_03_en.avif 230w", "/en/static/2ea34a24c5900a0f15f2abc26756c1c3/d1af7/VLC_Playlist_03_en.avif 460w", "/en/static/2ea34a24c5900a0f15f2abc26756c1c3/c107e/VLC_Playlist_03_en.avif 550w"],
              "sizes": "(max-width: 550px) 100vw, 550px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2ea34a24c5900a0f15f2abc26756c1c3/a0b58/VLC_Playlist_03_en.webp 230w", "/en/static/2ea34a24c5900a0f15f2abc26756c1c3/bc10c/VLC_Playlist_03_en.webp 460w", "/en/static/2ea34a24c5900a0f15f2abc26756c1c3/12b65/VLC_Playlist_03_en.webp 550w"],
              "sizes": "(max-width: 550px) 100vw, 550px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2ea34a24c5900a0f15f2abc26756c1c3/e83b4/VLC_Playlist_03_en.jpg 230w", "/en/static/2ea34a24c5900a0f15f2abc26756c1c3/e41a8/VLC_Playlist_03_en.jpg 460w", "/en/static/2ea34a24c5900a0f15f2abc26756c1c3/d7854/VLC_Playlist_03_en.jpg 550w"],
              "sizes": "(max-width: 550px) 100vw, 550px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2ea34a24c5900a0f15f2abc26756c1c3/d7854/VLC_Playlist_03_en.jpg",
              "alt": "VLC Player for your INSTAR IP Camera",
              "title": "VLC Player for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Type in the network stream address and click on `}<strong parentName="p">{`Enqeue`}</strong>{`.`}</p>
    <p><strong parentName="p">{`Step 3`}</strong>{`: Type in the network stream address for your HD or VGA camera (see above for details) and click on `}<strong parentName="p">{`Enqeue`}</strong>{` to add the URL to your playlist.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/476d80bb6acdb0e66e965e3d00d97ab0/b4294/VLC_Playlist_09_en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHqVm5gof/EABkQAQEBAAMAAAAAAAAAAAAAAAEAAhESQf/aAAgBAQABBQIM3Q4cl5Lf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBAAAgMAAAAAAAAAAAAAAAAAADEBIEH/2gAIAQEABj8CwUCp/8QAGxABAQACAwEAAAAAAAAAAAAAAQAhkRExYbH/2gAIAQEAAT8hQZFCj4RHpqPBqHFyN//aAAwDAQACAAMAAAAQFA//xAAVEQEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAwEBPxCH/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHRABAAMAAQUAAAAAAAAAAAAAAQARITFRYXGBof/aAAgBAQABPxBSKBl7WR8KO0Fy3IddSHxKRj6aj3Pjmf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/476d80bb6acdb0e66e965e3d00d97ab0/e4706/VLC_Playlist_09_en.avif 230w", "/en/static/476d80bb6acdb0e66e965e3d00d97ab0/d1af7/VLC_Playlist_09_en.avif 460w", "/en/static/476d80bb6acdb0e66e965e3d00d97ab0/56a33/VLC_Playlist_09_en.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/476d80bb6acdb0e66e965e3d00d97ab0/a0b58/VLC_Playlist_09_en.webp 230w", "/en/static/476d80bb6acdb0e66e965e3d00d97ab0/bc10c/VLC_Playlist_09_en.webp 460w", "/en/static/476d80bb6acdb0e66e965e3d00d97ab0/e88ff/VLC_Playlist_09_en.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/476d80bb6acdb0e66e965e3d00d97ab0/e83b4/VLC_Playlist_09_en.jpg 230w", "/en/static/476d80bb6acdb0e66e965e3d00d97ab0/e41a8/VLC_Playlist_09_en.jpg 460w", "/en/static/476d80bb6acdb0e66e965e3d00d97ab0/b4294/VLC_Playlist_09_en.jpg 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/476d80bb6acdb0e66e965e3d00d97ab0/b4294/VLC_Playlist_09_en.jpg",
              "alt": "VLC Player for your INSTAR IP Camera",
              "title": "VLC Player for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Go to `}<strong parentName="p">{`Media`}</strong>{` if you want to save the playlist.`}</p>
    <p><strong parentName="p">{`Step 4`}</strong>{`: You can now save the playlist to a file or add more cameras to it before saving it to a file. This allows you to quickly access your cameras in the future.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d8cd9fd14468536e46ddbed3cb3fa33b/b4294/VLC_Playlist_04_en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdaXYiA//8QAGhABAAIDAQAAAAAAAAAAAAAAAQACEBESIf/aAAgBAQABBQLg0VI1IYs+/wD/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAYEAACAwAAAAAAAAAAAAAAAAAAMQEgMv/aAAgBAQAGPwJGRRT/xAAZEAEBAQEBAQAAAAAAAAAAAAABABEhMcH/2gAIAQEAAT8hE6EzmSO/CWanrDKz/9oADAMBAAIAAwAAABD0D//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EAB0QAQEAAQQDAAAAAAAAAAAAAAERACExQVFhcaH/2gAIAQEAAT8QbD31eLiROssNsHHFheqK16yIIR30mCnxPuf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d8cd9fd14468536e46ddbed3cb3fa33b/e4706/VLC_Playlist_04_en.avif 230w", "/en/static/d8cd9fd14468536e46ddbed3cb3fa33b/d1af7/VLC_Playlist_04_en.avif 460w", "/en/static/d8cd9fd14468536e46ddbed3cb3fa33b/56a33/VLC_Playlist_04_en.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d8cd9fd14468536e46ddbed3cb3fa33b/a0b58/VLC_Playlist_04_en.webp 230w", "/en/static/d8cd9fd14468536e46ddbed3cb3fa33b/bc10c/VLC_Playlist_04_en.webp 460w", "/en/static/d8cd9fd14468536e46ddbed3cb3fa33b/e88ff/VLC_Playlist_04_en.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d8cd9fd14468536e46ddbed3cb3fa33b/e83b4/VLC_Playlist_04_en.jpg 230w", "/en/static/d8cd9fd14468536e46ddbed3cb3fa33b/e41a8/VLC_Playlist_04_en.jpg 460w", "/en/static/d8cd9fd14468536e46ddbed3cb3fa33b/b4294/VLC_Playlist_04_en.jpg 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d8cd9fd14468536e46ddbed3cb3fa33b/b4294/VLC_Playlist_04_en.jpg",
              "alt": "VLC Player for your INSTAR IP Camera",
              "title": "VLC Player for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/abe8c8f70e2f42d87f0f71fa0494c517/b4294/VLC_Playlist_05_en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdZ50EED/8QAGRABAQEBAQEAAAAAAAAAAAAAAQACIRET/9oACAEBAAEFAjI3zJyRy9tPf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABgQAAMBAQAAAAAAAAAAAAAAAAABMSCR/9oACAEBAAY/AoiLhFj/xAAaEAADAQADAAAAAAAAAAAAAAAAAREhEMHw/9oACAEBAAE/IUkdOELPoKp5p5pUf//aAAwDAQACAAMAAAAQgA//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAdEAEBAAIBBQAAAAAAAAAAAAABEQAhMUFRYXGR/9oACAEBAAE/EKwAlpZq5qgPrB86OWGwxWTDQd/WMDHjv5c//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/abe8c8f70e2f42d87f0f71fa0494c517/e4706/VLC_Playlist_05_en.avif 230w", "/en/static/abe8c8f70e2f42d87f0f71fa0494c517/d1af7/VLC_Playlist_05_en.avif 460w", "/en/static/abe8c8f70e2f42d87f0f71fa0494c517/56a33/VLC_Playlist_05_en.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/abe8c8f70e2f42d87f0f71fa0494c517/a0b58/VLC_Playlist_05_en.webp 230w", "/en/static/abe8c8f70e2f42d87f0f71fa0494c517/bc10c/VLC_Playlist_05_en.webp 460w", "/en/static/abe8c8f70e2f42d87f0f71fa0494c517/e88ff/VLC_Playlist_05_en.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/abe8c8f70e2f42d87f0f71fa0494c517/e83b4/VLC_Playlist_05_en.jpg 230w", "/en/static/abe8c8f70e2f42d87f0f71fa0494c517/e41a8/VLC_Playlist_05_en.jpg 460w", "/en/static/abe8c8f70e2f42d87f0f71fa0494c517/b4294/VLC_Playlist_05_en.jpg 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/abe8c8f70e2f42d87f0f71fa0494c517/b4294/VLC_Playlist_05_en.jpg",
              "alt": "VLC Player for your INSTAR IP Camera",
              "title": "VLC Player for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 5`}</strong>{`: Make sure that the advanced controls are activated if you want to record the live stream.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/05c8902fbedcee4093c1c9cf20c8ae81/b4294/VLC_Playlist_06_en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIDBAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHWeIGKwf/EABoQAQEAAgMAAAAAAAAAAAAAAAEAAgMSEzH/2gAIAQEAAQUCMRusnEjznO2//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRABAQEAAwAAAAAAAAAAAAAAADEBEUGR/9oACAEBAAY/AukzxMcoj//EABsQAAMAAgMAAAAAAAAAAAAAAAABERBRwdHw/9oACAEBAAE/IU0arCF3wD6qz1Sz7n//2gAMAwEAAgADAAAAEOMP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHBABAQEAAgMBAAAAAAAAAAAAAREAITFBUXHw/9oACAEBAAE/EHTQS8VOLuCAfBhR4PXP1QVhLpAb2e11+9b/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/05c8902fbedcee4093c1c9cf20c8ae81/e4706/VLC_Playlist_06_en.avif 230w", "/en/static/05c8902fbedcee4093c1c9cf20c8ae81/d1af7/VLC_Playlist_06_en.avif 460w", "/en/static/05c8902fbedcee4093c1c9cf20c8ae81/56a33/VLC_Playlist_06_en.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/05c8902fbedcee4093c1c9cf20c8ae81/a0b58/VLC_Playlist_06_en.webp 230w", "/en/static/05c8902fbedcee4093c1c9cf20c8ae81/bc10c/VLC_Playlist_06_en.webp 460w", "/en/static/05c8902fbedcee4093c1c9cf20c8ae81/e88ff/VLC_Playlist_06_en.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/05c8902fbedcee4093c1c9cf20c8ae81/e83b4/VLC_Playlist_06_en.jpg 230w", "/en/static/05c8902fbedcee4093c1c9cf20c8ae81/e41a8/VLC_Playlist_06_en.jpg 460w", "/en/static/05c8902fbedcee4093c1c9cf20c8ae81/b4294/VLC_Playlist_06_en.jpg 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/05c8902fbedcee4093c1c9cf20c8ae81/b4294/VLC_Playlist_06_en.jpg",
              "alt": "VLC Player for your INSTAR IP Camera",
              "title": "VLC Player for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 6`}</strong>{`: You can start the live streaming by clicking on the Play button.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/edf765c47c433fce47f61a7ce6d93d57/b4294/VLC_Playlist_07_en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "99.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAEEAwUG/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAGrNeVvtWZ6TIKB/8QAGhAAAwADAQAAAAAAAAAAAAAAAAECAxESE//aAAgBAQABBQL1snJdXWXTWh8ncoSSEpZzJ//EABURAQEAAAAAAAAAAAAAAAAAABIg/9oACAEDAQE/AVH/xAAVEQEBAAAAAAAAAAAAAAAAAAARIP/aAAgBAgEBPwEj/8QAHxAAAQMDBQAAAAAAAAAAAAAAAAECkRExMhASIUFx/9oACAEBAAY/ArlN9PThUFOtLJBi2DBsH//EABsQAAICAwEAAAAAAAAAAAAAAAABESExQbHB/9oACAEBAAE/Ic1xeRF24DoWRp32PC6Cop0IvMZAibPCf//aAAwDAQACAAMAAAAQqPc8/8QAGBEAAgMAAAAAAAAAAAAAAAAAESEAARD/2gAIAQMBAT8QskI9/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAP/8QAHhABAAICAQUAAAAAAAAAAAAAAQARITFBUXGBwfD/2gAIAQEAAT8QtEuC6DjxLFzGiO2pSDBVhzKElL4vhmPq0gGcIZBrDaeoJl0dMzjC18E//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/edf765c47c433fce47f61a7ce6d93d57/e4706/VLC_Playlist_07_en.avif 230w", "/en/static/edf765c47c433fce47f61a7ce6d93d57/d1af7/VLC_Playlist_07_en.avif 460w", "/en/static/edf765c47c433fce47f61a7ce6d93d57/56a33/VLC_Playlist_07_en.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/edf765c47c433fce47f61a7ce6d93d57/a0b58/VLC_Playlist_07_en.webp 230w", "/en/static/edf765c47c433fce47f61a7ce6d93d57/bc10c/VLC_Playlist_07_en.webp 460w", "/en/static/edf765c47c433fce47f61a7ce6d93d57/e88ff/VLC_Playlist_07_en.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/edf765c47c433fce47f61a7ce6d93d57/e83b4/VLC_Playlist_07_en.jpg 230w", "/en/static/edf765c47c433fce47f61a7ce6d93d57/e41a8/VLC_Playlist_07_en.jpg 460w", "/en/static/edf765c47c433fce47f61a7ce6d93d57/b4294/VLC_Playlist_07_en.jpg 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/edf765c47c433fce47f61a7ce6d93d57/b4294/VLC_Playlist_07_en.jpg",
              "alt": "VLC Player for your INSTAR IP Camera",
              "title": "VLC Player for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 7`}</strong>{`: You can start the live stream recording by clicking on the `}<strong parentName="p">{`Rec`}</strong>{` button. The file will be saved in `}<strong parentName="p">{`My Documents`}</strong>{` inside your `}<strong parentName="p">{`My Videos`}</strong>{` folder.`}</p>
    <h3 {...{
      "id": "troubleshooting-choppy-video",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#troubleshooting-choppy-video",
        "aria-label": "troubleshooting choppy video permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Troubleshooting choppy video`}</h3>
    <p>{`When large HD videos are opened in VLC Media Player, the HD video does not always play perfectly. The HD video may freeze and some frames are skipped while the audio plays perfectly. The video might hang for a few seconds and start playing again at the same point.`}</p>
    <h4 {...{
      "id": "increase-the-cache-values",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#increase-the-cache-values",
        "aria-label": "increase the cache values permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Increase the cache values.`}</h4>
    <p>{`Even if you play an offline video, VLC needs to cache it. It loads a certain part of the video from the hard disk into the main memory or RAM of your computer. From there, the player accesses it and plays it. It happens all the time while a video is playing. For HD video, it helps to increase the amount of video in the buffer to ensure that there is always enough video to play the video smoothly.`}</p>
    <p>{`This way you increase the buffering of files:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/45139424797dc38e34650e30923d9d85/e9beb/VLC_Cache_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABmklEQVQoz6XNvUsCcRzH8a+X2tQQtEdQg5b5QEP3u+OEFFvyIYKi/oemkGqKSJf6DxraWkLblBoiepAG74wgSsO786f5kN7Z1WlCU1ARlvYAvfiu788XJmIh++GO4zjs4CJjbMTJRWxHu6aDHXQaIk9CKBZGsTB5GnImoq6L/fc733OxERcXBa/b7Zlwez0en9sz5Zt02O2bW9v5YpVPpvI4+6SqSrX63Ggs+f1TPt/czMzs9PTHQTOtVgsAG6t+pVJi49zV5VWxUCzkC6qqGg0GACAI4lNANOnU6wFgfW25UVNT1ymcwZIkZTGWZdlmswGATqfraNLmcyAQLJclNs5inJPlKsbZckWymM1tPrfGwWCg9nCfTiXzuWzlrqTIUqP+aLFa/hSvzC9kEumz6MnFUSLNJW/iSZFLD/cP/BJrNBoA6O3uGewdGrcaFydH/F6rw2oy9Zm7tLo2AdHibeLrLtEG/AvD2GmapigKIUS9QgiN/ogkSZqiGYaB3O0tz/OCIIiiKAgCz/OiKNa+V6/VZbmcKWUURXkBlrnlIp/lXVAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/45139424797dc38e34650e30923d9d85/e4706/VLC_Cache_01.avif 230w", "/en/static/45139424797dc38e34650e30923d9d85/d1af7/VLC_Cache_01.avif 460w", "/en/static/45139424797dc38e34650e30923d9d85/b6582/VLC_Cache_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/45139424797dc38e34650e30923d9d85/a0b58/VLC_Cache_01.webp 230w", "/en/static/45139424797dc38e34650e30923d9d85/bc10c/VLC_Cache_01.webp 460w", "/en/static/45139424797dc38e34650e30923d9d85/87ca7/VLC_Cache_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/45139424797dc38e34650e30923d9d85/81c8e/VLC_Cache_01.png 230w", "/en/static/45139424797dc38e34650e30923d9d85/08a84/VLC_Cache_01.png 460w", "/en/static/45139424797dc38e34650e30923d9d85/e9beb/VLC_Cache_01.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/45139424797dc38e34650e30923d9d85/e9beb/VLC_Cache_01.png",
              "alt": "VLC Player for your INSTAR IP Camera",
              "title": "VLC Player for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open the player settings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3db0063e458518ba91b7a73cb0e3d1f6/e9beb/VLC_Cache_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "91.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAChklEQVQ4y22SyW4UMRCG+9XIgQNPgJA4cOSKEAdeAMETIN6B7QbigAKRQAJEIEPvi7f20rbLdjuaTGYuqLsTmAi+Q6ktddVv//Un4JwJaz/G9dnZ+fn5dreby3a73Z5vt7vpuJm+99hsNmczyWFavfi+qolqv70L8TQ7fG04//XlI8GEV9/b9JuRrDk+8iE6AHeV5MPxyYujz3nVFceffAi0OLZGr37+wAjjpijTn1LKOl/5EPw/JF3TlllRlmVRNRbAhQgAIQTnwPkQxuic82F0/yMZBo0QBoAYx2mac3OZGo3WPWPDMCgp1VWEEH3fJ5yLNMsIZc75/anee621EGKY0VovdUEpRSlNAACs8WC9cwDAGKOUaq0X/TA/VQhBKHXgxnEMMwAwNYcxhvXOr3fhdN00TTE9vyyKQgjp5nFCCM65EEJKudzIe3/R/Pzlq2sHBzeuH9x78LDvWZplVVUhhJefnHMY46qs2rYtq0oNw3KXi+Y3b9/euX3z/t1bj5886vu+LMu27ay1f/ZBKe26jnMupRyG4YpyjHEKzXZ3tlm3bZsXRZ4XSqlwsViXpmlRlIQQSinn/IoyYyzLsjzLCSGXDX9tN8ZYaydLrVl8hjln1tqpue/71cnq5GSVZxkhFGOCEBJCzDlxfc/pBGOMKaUAwHv/122lVF3XTTPFK+xlMIQwBbOuMcZohjEmpVxs55wTQhIhRDWDELLW7ocEAIwxcIm11hizJGQZkQjOK4TyskrTbNnEkg1j9GLbPjFGYwxlrJ+dS3pjmqfPqsMPNcb0wrNJdtEZ/+E0xtMYx3EEgKRBqHv/UbWow7iua631pbjft8B5PwYvNHzNSY4F6joh5G+0eMxaBn8/ZgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3db0063e458518ba91b7a73cb0e3d1f6/e4706/VLC_Cache_02.avif 230w", "/en/static/3db0063e458518ba91b7a73cb0e3d1f6/d1af7/VLC_Cache_02.avif 460w", "/en/static/3db0063e458518ba91b7a73cb0e3d1f6/b6582/VLC_Cache_02.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3db0063e458518ba91b7a73cb0e3d1f6/a0b58/VLC_Cache_02.webp 230w", "/en/static/3db0063e458518ba91b7a73cb0e3d1f6/bc10c/VLC_Cache_02.webp 460w", "/en/static/3db0063e458518ba91b7a73cb0e3d1f6/87ca7/VLC_Cache_02.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3db0063e458518ba91b7a73cb0e3d1f6/81c8e/VLC_Cache_02.png 230w", "/en/static/3db0063e458518ba91b7a73cb0e3d1f6/08a84/VLC_Cache_02.png 460w", "/en/static/3db0063e458518ba91b7a73cb0e3d1f6/e9beb/VLC_Cache_02.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3db0063e458518ba91b7a73cb0e3d1f6/e9beb/VLC_Cache_02.png",
              "alt": "VLC Player for your INSTAR IP Camera",
              "title": "VLC Player for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Activate the display of `}<strong parentName="p">{`all settings`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c57baf24dc36bdff8a6727381e8c19ba/e9beb/VLC_Cache_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "91.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADQklEQVQ4yz2TS28bVRTH5+vxESgbBAZWLNln302RXClxWpuSlKqBtApuEhxREju2aztS26SpizJ3JvO68/Lc57wf9qAZk/50dFf36H8e/yN8eX/jh5c7jWePGnudxv6Tz/H1Xvve7uZXu5v3djcbL379ro7Giyff7v/yzV77++5vX/z0ozDo94s8D4MgS9O8jixJlnnuM34LZKgbt7LMCPUZ9ykLfT9LkigMy7J8uvtU6PV6qqJIQOKcR1EUhmEURYwx27bn8/n795eaphuGoRuGruumafq+zxgriuLxo8fCbDYLwxAjXBRFXrNcLrnvm9CEEAIROI4TBAGvoZRijBFCSZJ0Oh1hOp1xxi3LStM0y7I0TYs855ybpimK4OrqAwASY4zWEEI4pYzSoiiq5MH5OSHYMq0wDP9PLgrOOTQtTdNFABDG9A5CKY1jEgZ5ltXKk0kYBAt3sZ5ZmiRFlvHAN0Vw2z1UX58yn1eKGBNKievit+/cDx+DOGm3O8L04iKMY82AfhQVZZktl8uy9OMYGlC/uhbfvoOmiTGihFDOqQFxq23/caA4eKu1LZztv5Rf/XX9+75y3LNP+9Y/Z/ZpXzvuQQBk3bj+OFcUldR9Ut+nqoYfbnkHrzQIW1tbwvj0zBPBv28mcP4pMC2uGwE0PUmGqqbrOhCBoiiWZeG7zrFpLiA0LbvValVlZ0XhYpyvVkVZ5qvVqiyDODYtC4hgPv8ky7JtO+tkxtjC80QAoAG3t7eF6XRKMZGBVA2snnaaplEUeZ6naZquG67rIoTWSyaUYoRcx/E8r1KeTCZRFEHDCIIgz/OsJk1TQikA4PLyEoCqcs/z1ntmjKmq6rqLZrMpDAbnCFUicZxkdyRJgglRVVWWby3b9jwPY8zufKIoim3bVfJsdpEmiaqoruN+dmitTG5uxFpZWiwWlmUTQhBGmFT29Dzvwc8PhPF4TDCRKieh2iMV654lSbqp0TXdsiyE8aIG1z8r5fH4TZpmQVBdWVmWq1UVRbEMwwgh5FRUA1vfwxrHcVar1c7OjvCw2ex2uycnJ0dHR4eHh/1+fzwej0aj9btmOByeD4ej0fD12eD5nycHx38P+v2NjY3/ALSaqNH82gHyAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c57baf24dc36bdff8a6727381e8c19ba/e4706/VLC_Cache_03.avif 230w", "/en/static/c57baf24dc36bdff8a6727381e8c19ba/d1af7/VLC_Cache_03.avif 460w", "/en/static/c57baf24dc36bdff8a6727381e8c19ba/b6582/VLC_Cache_03.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c57baf24dc36bdff8a6727381e8c19ba/a0b58/VLC_Cache_03.webp 230w", "/en/static/c57baf24dc36bdff8a6727381e8c19ba/bc10c/VLC_Cache_03.webp 460w", "/en/static/c57baf24dc36bdff8a6727381e8c19ba/87ca7/VLC_Cache_03.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c57baf24dc36bdff8a6727381e8c19ba/81c8e/VLC_Cache_03.png 230w", "/en/static/c57baf24dc36bdff8a6727381e8c19ba/08a84/VLC_Cache_03.png 460w", "/en/static/c57baf24dc36bdff8a6727381e8c19ba/e9beb/VLC_Cache_03.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c57baf24dc36bdff8a6727381e8c19ba/e9beb/VLC_Cache_03.png",
              "alt": "VLC Player for your INSTAR IP Camera",
              "title": "VLC Player for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Go to `}<strong parentName="p">{`Input / Codecs`}</strong>{`, `}<strong parentName="p">{`Advanced`}</strong>{` and increase the `}<strong parentName="p">{`File & Disk Cache`}</strong>{` value to 500 - 1000ms (default is 300ms). Please note that too high a value can have negative effects again.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      